<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('ball_game.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('ball_game.head_list7') }}{{ title }}</v-card-title>
        <v-col class="pb-4 px-0">
          <v-row v-for="(item, n) in footballData.match" :key="n" class="ma-0">
            <v-card-title class="pb-0">
              คู่ที่ {{ n + 1 }}: {{ item.homeName }} {{ item.homeScore }} - {{ item.awayScore }}
              {{ item.awayName }}</v-card-title>
          </v-row>
        </v-col>

        <v-card-actions>
          <v-row no-gutters class="d-flex align-center justify-end">
            <span class="px-3">{{ $t('search') }}:</span>
            <div style="width: 150px">
              <v-text-field v-debounce:500ms="searchKeyword" :date="searchKeyword" outlined dense hide-details />
            </div>
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="value"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.actionDetail`]="{ item }">
              <v-btn :color="item.status === 'success' ? 'success' : 'error'" class="white--text text-capitalize"
                :disabled="item.status === 'success'" @click="item.status === 'success' ? null : transferReward(item)">
                {{ item.status === 'success' ? $t('ball_game.sended') : $t('ball_game.send') }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card-actions>

        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="$router.push({ name: 'predict-football' })">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="px-3">{{ $t('back') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import TextInput from '@/components/input/TextInput.vue'
import store from '@/store'

export default {
  components: {
    DatePickerInput,
    TextInput,
  },

  data() {
    return {
      title: '',
      search: '',
      loading: false,
      canCreate: false,
      rules: [
        value => !!value || this.$t('alert_list.alert1'),
        value => (!!value && /^\d+$/.test(value)) || this.$t('alert_list.alert2'),
      ],
      headers: [
        {
          text: this.$t('ball_game.header1'),
          value: 'id',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('ball_game.header9'),
          value: 'user',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('ball_game.header11'),
          value: 'createdAt',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'รายละเอียด',
        //   value: 'actionDetail',
        //   width: '150px',
        //   align: 'center',
        //   sortable: false,
        // },
      ],
      value: [],
      footballData: {},
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    this.getPermissionsEdit()

    this.getFootballData()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) {
          this.pagination.page = newValue.page
          cont = true
        }
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          this.value = []
          await this.getResultFootballData(newValue.page, newValue.itemsPerPage)
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'ทายบอล',
            url: window.location.href,
            detail: 'รายการ ผลออกรางวัลทายบอล',
            admin: userSystem.name ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getFootballData() {
      const id = this.$route.params.id
      this.loading = true
      try {
        let res = await this.$store.dispatch('getFootballList', `id=${id}`)
        let resData = res.data[0]
        this.footballData = resData
        this.title = resData.title
        this.loading = false
      } catch (e) { }
    },
    async getResultFootballData(page, row) {
      const id = this.$route.params.id
      try {
        let result = await this.$store.dispatch(
          'getFootballGameUserReward',
          `page=${page}&rows=${row}&predictFootballGameId=${id}`,
        )
        let resData = result.data

        for (let i = 0; i < resData.length; i++) {
          this.value.push({
            id: resData[i].id || null,
            homeName: resData[i].predictFootballGameMatch.homeName,
            awayName: resData[i].predictFootballGameMatch.awayName,
            scorePredict: `${resData[i].homePredict} - ${resData[i].awayPredict}`,
            memberId: resData[i].member.id,
            predictGameId: resData[i].predictFootballGame.id,
            user: resData[i].member.phone || '-',
            createdAt: moment(resData[i].createdAt).format('YYYY-MM-DD HH:mm') || '-',
            status: resData[i].status,
          })
        }
        this.pagination.totalItems = result.count
      } catch (e) { }
    },
    async transferReward(data) {
      const rewardData = {
        memberId: data.memberId,
        predictGameId: data.predictGameId,
      }

      try {
        let result = await this.$store.dispatch('transferFootballRewardToMember', rewardData)
        this.value = []
        this.getResultFootballData(this.pagination.page, this.pagination.rowsPerPage)
      } catch (e) {
        this.$swal.fire({
          icon: 'error',
          title: `${this.$t('ball_game.fail_send')} [${e.message}]`,
        })
      }
    },

    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'predictFootball')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
        this.headers = [
          ...this.headers,
          {
            text: this.$t('ball_game.header8'),
            value: 'actionDetail',
            width: '150px',
            align: 'center',
            sortable: false,
          },
        ]
      }
    },
    async searchKeyword(value) {
      this.loading = true
      const id = this.$route.params.id
      this.value = []
      let result = {}
      if (value) {
        result = await this.$store.dispatch(
          'getFootballGameUserReward',
          `page=1&rows=${this.pagination.rowsPerPage}&search=${value}&predictFootballGameId=${id}`,
        )
      } else {
        result = await this.$store.dispatch(
          'getFootballGameUserReward',
          `page=1&rows=${this.pagination.rowsPerPage}&predictFootballGameId=${id}`,
        )
      }
      let resData = result.data
      for (let i = 0; i < resData.length; i++) {
        this.value.push({
          id: resData[i].id || null,
          homeName: resData[i].predictFootballGameMatch.homeName,
          awayName: resData[i].predictFootballGameMatch.awayName,
          scorePredict: `${resData[i].homePredict} - ${resData[i].awayPredict}`,
          user: resData[i].member.phone || '-',
          createdAt: moment(resData[i].createdAt).format('YYYY-MM-DD HH:mm') || '-',
          status: resData[i].status,
        })
      }

      this.pagination.totalItems = result.count
      this.pagination.page = 1
      this.loading = false
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
  },
}
</script>
